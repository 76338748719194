import { ApiResponse } from '../../shared/interfaces/api-response';
import { CustomFieldMetadata } from '../typings/custom-field-metadata';
import { ApiModel } from '../../shared/enums/api-model';
import { CustomFieldType } from '../typings/custom-field-type';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { CustomFieldValue } from '../typings/custom-field-value';
import { BusinessDate } from '../../shared/utils/business-date';

export interface ModelCustomFieldResponse extends ApiResponse {
    created_at: string;
    deleted_at: string | null;
    id: number;
    key: string;
    name: string;
    pivot: {
        created_at: string;
        custom_field_id: number;
        default: any
        has_interval: boolean
        id: number;
        metadata: CustomFieldMetadata;
        model: ApiModel;
        object_id: number;
        object_type: string
        required: boolean
        updated_at: string
        validator: string | null
    };
    type: CustomFieldType;
    updated_at: string;
    // Only if attached to a model
    from: string | null;
    // Only if attached to a model
    to: string | null;
    // Only if attached to a model
    value: string | null;
}

/**
 * Custom field related to a customer and their models
 */
export class ModelCustomField {
    protected readonly _originalValue: unknown;
    protected _value: CustomFieldValue = null;
    readonly response: ModelCustomFieldResponse;

    type: CustomFieldType = 'string';
    customFieldId: number;
    default: string | null;
    hasInterval: boolean;
    id: number;
    pivotId: number;
    metadata: CustomFieldMetadata;
    model: ApiModel;
    objectId: number;
    objectType: string;
    translationKey: string;
    key: string;
    required: boolean;
    name: string;
    stringifiedMetadata = '';
    selectOptions: { value: string, translationKey: string }[] = [];
    validator: string | null;
    /**
     * Used to sort from low to high
     */
    priority: number;

    // These items exist on fields that are attached to models
    from: DateTime | null;
    to: DateTime | null;
    creatable = false;
    editable = false;
    deletable = false;

    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    deletedAt: DateTime | null;

    constructor(data: ModelCustomFieldResponse) {
        this.response = data;
        this.id = data.id;
        this.key = data.key;
        this.name = data.name;
        this.customFieldId = data.pivot.custom_field_id;
        this.default = data.pivot.default;
        this.hasInterval = data.pivot.has_interval;
        this.pivotId = data.pivot.id;
        this.metadata = data.pivot.metadata;
        this.model = data.pivot.model;
        this.objectId = data.pivot.object_id;
        this.objectType = data.pivot.object_type;
        this.required = data.pivot.required;
        this.translationKey = data.name;
        this.validator = data.pivot.validator;
        this.type = data.type;
        this.priority = data.pivot.metadata?.priority || 0;

        this.from = data.from ? stringToDateTime(data.from) : null;
        this.to = data.to ? stringToDateTime(data.to) : null;
        this.creatable = data.value == null || data.pivot.has_interval;
        this.editable = data.value != null;
        this.deletable = !data.pivot.required && data.value != null;

        this.createdAt = data.created_at ? stringToDateTime(data.created_at) : null;
        this.updatedAt = data.updated_at ? stringToDateTime(data.updated_at) : null;
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;

        try {
            this.stringifiedMetadata = typeof this.metadata === 'object' && this.metadata != null ? JSON.stringify(this.metadata) : '';
        } catch (e) {
            console.error(e);
        }

        // Other functions
        this.setSelectOptions();

        // Assign value after all other properties have been set
        this.value = data.value;
        this._originalValue = data.value;
    }

    clone(data: Partial<ModelCustomFieldResponse> = {}) {
        return new ModelCustomField({ ...this.response, ...data });
    }

    // Returns all select options as they are in the pivot's metadata
    getSelectOptions(): Record<string, string> {
        if (this.type !== 'select') {
            return {};
        }

        if (this.metadata == null || !this.metadata.options) {
            return {};
        }

        if (Array.isArray(this.metadata.options)) {
            return Object.entries(this.metadata.options).reduce((carry, [ key, value ]) => {
                carry[key] = value;
                return carry;
            }, {} as Record<string, string>);
        }

        return this.metadata.options;
    }

    // Is this field active atm?
    get active() {
        const now = DateTime.now();

        if (!('from' in this && 'to' in this)) {
            return;
        }

        if (this.from) {
            const afterOrEqualFrom = this.from <= now;
            return this.to ? afterOrEqualFrom && this.to >= now : afterOrEqualFrom;
        }

        return true;
    }

    get isModified() {
        if (this._originalValue == null && this._value == null) {
            return false;
        }
        return this._originalValue !== this.toString();
    }

    get value(): CustomFieldValue {
        return this._value;
    }

    set value(value: unknown) {
        switch (this.type) {
            case 'decimal':
            case 'integer': {
                this.setNumberValue(value);
                break;
            }
            case 'boolean': {
                this.setBooleanValue(value);
                break;
            }
            case 'date': {
                this.setDateValue(value);
                break;
            }
            case 'select': {
                this._value = value == null ? value : String(value);
                break;
            }
            default: {
                this._value = value as CustomFieldValue;
            }
        }
    }

    // Returns the value as a string, or null if the value is null
    toString() {
        const value = this._value;

        if (value == null) {
            return null;
        }
        if (value instanceof BusinessDate) {
            return value.toString();
        }
        if (typeof value === 'boolean') {
            return String(+value);
        }

        return value.toString();
    }

    // Returns the option that is currently selected when the field type is 'select'
    getSelectedOption() {
        if (this.type !== 'select') {
            return;
        }

        return this.selectOptions.find((option) => option.value === this.value);
    }

    private setNumberValue(value: unknown) {
        if (value == null) {
            this._value = null;
        }
        if (typeof value === 'string') {
            this._value = Number(value);
        }
        if (typeof value === 'number') {
            this._value = value;
        }
    }

    private setBooleanValue(value: unknown) {
        if (value == null) {
            this._value = null;
        }
        if (typeof value === 'string') {
            this._value = value === '1';
        }
        if (typeof value === 'boolean') {
            this._value = value;
        }
    }

    private setDateValue(value: unknown) {
        if (typeof value === 'string' || value instanceof DateTime) {
            this._value = new BusinessDate(value);
        } else if (value instanceof BusinessDate) {
            this._value = value;
        } else {
            this._value = null;
        }
    }

    private setSelectOptions() {
        const resetOption = this.required ? [] : [ { value: '', translationKey: '' } ];
        const options = Object.entries(this.getSelectOptions()).map(([ key, value ]) => {
            return {
                value: key,
                translationKey: value,
            };
        });

        this.selectOptions = [ ...resetOption, ...options ];
    }
}

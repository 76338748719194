import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Duration } from 'luxon';
import { NamespaceFile } from '../enums/namespace';
import { TranslateService } from './translate.service';
import { UIRouter } from '@uirouter/core';

interface SnackCallback {
    text: string,
    action: () => void,
}

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(
        @Inject(MatSnackBar) public snackbar: MatSnackBar,
        @Inject(TranslateService) public translate: TranslateService,
        @Inject(UIRouter) protected uiRouter: UIRouter,
    ) {
    }

    created() {
        void this.t('CREATED');
    }

    updated() {
        void this.t('UPDATED');
    }

    deleted() {
        void this.t('DELETED');
    }

    /**
     * @deprecated
     * @see t
     */
    tToast(string: string, variables?: Record<string, string | number>) {
        const split = string.split(':');
        return this.t(
            string.includes(':') ? split[1] as string : split[0] as string,
            string.includes(':') ? split[0] as NamespaceFile : undefined,
            variables,
        );
    }

    async t(key: string, ns?: NamespaceFile, variables?: Record<string, string | number>, callback?: SnackCallback) {
        this.open(await this.translate.t(key, ns, variables), callback);
    }

    /**
     * Open a snackbar
     * @param text
     * @param callback
     * @param duration - In seconds
     */
    open(text: string, callback?: SnackCallback, duration?: number) {
        const seconds = duration ?? callback?.action ? 8 : 5;
        const snackbar = this.snackbar.open(text, callback?.text, {
            duration: Duration.fromObject({ seconds }).as('milliseconds'),
        });

        snackbar.onAction().subscribe(() => callback?.action());

        return snackbar;
    }

    /**
     * @deprecated
     * Use the open function
     */
    toast(text: string, options?: { action?: { text?: string, callback?: () => void, state?: { name: string, params: Record<string, any> } } }) {
        let callback: SnackCallback | undefined;

        if (options?.action) {
            if (options.action.callback) {
                callback = {
                    text: options.action.text || '',
                    action: options.action.callback,
                };
            }

            if (options.action.state) {
                const name = options.action.state.name;
                const params = options.action.state.params;

                callback = {
                    text: options.action.text || '',
                    action: () => {
                        this.uiRouter.stateService.go(name, params);
                    },
                };
            }
        }

        this.open(text, callback);
    }
}

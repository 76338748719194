// @ts-ignore
const API = "https://api.easyatwork.com" || 'https://api.easyatwork.com';

export const environment = {
    isProduction: true,
    isLive: true,
    isTesting: false,
    googleAPIKey: 'AIzaSyAFHf9kPXqo1-QlzJs7yQjSP5L9S2PovbI',
    api: API,
    // @ts-ignore
    version: "buildkitsandbox",
};

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { root, trimHashVal } from '../common';
import { BaseLocationServices } from './baseLocationService';
/** A `LocationServices` that uses the browser hash "#" to get/set the current location */
var HashLocationService = /** @class */function (_super) {
  __extends(HashLocationService, _super);
  function HashLocationService(router) {
    var _this = _super.call(this, router, false) || this;
    root.addEventListener('hashchange', _this._listener, false);
    return _this;
  }
  HashLocationService.prototype._get = function () {
    return trimHashVal(this._location.hash);
  };
  HashLocationService.prototype._set = function (state, title, url, replace) {
    this._location.hash = url;
  };
  HashLocationService.prototype.dispose = function (router) {
    _super.prototype.dispose.call(this, router);
    root.removeEventListener('hashchange', this._listener);
  };
  return HashLocationService;
}(BaseLocationServices);
export { HashLocationService };

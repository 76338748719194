import { stringToDateTime } from '../utils/string-to-date-time';
import { DateTime } from 'luxon';
import { ApiResponse } from '../interfaces/api-response';
import { PropertyValueDecoder } from '../utils/property-value-decoder';

// Either a plain string or a JSON formatted string
export type PropertyValue = string;

export interface PropertyResponse extends ApiResponse {
    id: number,
    key: string,
    value: PropertyValue,
    created_at: string,
    updated_at: string,
    from?: string | null,
    to?: string | null
}

export class Property {
    readonly _response: PropertyResponse;

    id: number;
    key: string;
    value: PropertyValueDecoder;
    createdAt: DateTime | null;
    updatedAt: DateTime | null;
    from: DateTime | null;
    to: DateTime | null;

    constructor(prop: PropertyResponse) {
        this._response = prop;

        this.id = prop.id;
        this.key = prop.key;
        this.value = new PropertyValueDecoder(prop.value, undefined);
        this.createdAt = stringToDateTime(prop.created_at);
        this.updatedAt = stringToDateTime(prop.updated_at);
        this.from = prop.from ? stringToDateTime(prop.from) : null;
        this.to = prop.to ? stringToDateTime(prop.to) : null;
    }

    setValue(value: PropertyValue) {
        this.value = new PropertyValueDecoder(value, undefined);
    }
}

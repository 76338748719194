import { environment } from '../../../../../environments/environment';

export class EawUrl {
    // Set url immediately, so we don't have to do a check if the exists or not
    static url = environment.api;

    static getUrl(url: string) {
        return url.startsWith('/') ? `${this.url}${url}` : `${this.url}/${url}`;
    }

    /**
     * Sets url to passed in value as long as we're using the production api
     */
    static set(url: string = this.url) {
        // Don't fuck with the url if it's local
        this.url = environment.isLive ? url : this.url;
    }

    /**
     * Try to get new api url from headers and set if it exists
     */
    static handleHeader(resp: {headers: () => any}) {
        // X header is in lowercase, even though it looks different in networks tab
        const url = resp.headers()?.['x-api-url'];

        if (url === this.url) {
            return;
        }
        if (!this.isApiUrl(url)) {
            return;
        }

        // Set the new url we got
        this.set(url);
    }

    /**
     * Checks if the url in the response belongs to our API
     */
    static isApiUrl(url?: string): boolean {
        // Do quick check to exclude items we get from node_modules, html templates etc
        if (!url?.startsWith('http')) {
            return false;
        }

        return /^https?:\/\/((.+\.)?(localhost|easyatwork\.com|eatw\.io)|192\.168\.\d+\.\d+)(:\d+)?(\/|$)/g.test(url);
    }
}

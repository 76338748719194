import { ArrayPaginatedResponse, AssocPaginatedResponse } from '../../interfaces/paginated-response';
import { map, Observable } from 'rxjs';

type Constructor<I, C> = new(args: I) => C;
type ArrayPaginatedResponsePipe<I, C> = (observable: Observable<ArrayPaginatedResponse<I>>) => Observable<ArrayPaginatedResponse<C>>;
type AssocPaginatedResponseResponsePipe<I, C> = (observable: Observable<AssocPaginatedResponse<I>>) => Observable<AssocPaginatedResponse<C>>;
type TypeToClass<I, C> = (observable: Observable<I>) => Observable<C>;

export function classifyAssocPaginatedResponse<C, I>(Class: Constructor<I, C>): AssocPaginatedResponseResponsePipe<I, C> {
    return function(observable: Observable<AssocPaginatedResponse<I>>): Observable<AssocPaginatedResponse<C>> {
        return observable.pipe(map((resp) => {
            const assign: AssocPaginatedResponse<I> & { data: Record<string, C> } = Object.assign(resp, {
                data: Object.entries(resp.data).reduce((acc, [ key, attrs ]: [ string, I ]) => {
                    acc[key] = new Class(attrs);

                    return acc;
                }, {} as Record<string, C>),
            });

            return assign;
        }));
    };
}

export function classifyArrayPaginatedResponse<C, I>(Class: Constructor<I, C>): ArrayPaginatedResponsePipe<I, C> {
    return function(observable: Observable<ArrayPaginatedResponse<I>>): Observable<ArrayPaginatedResponse<C>> {
        return observable.pipe(map((resp) => {
            return Object.assign(resp, {
                data: resp.data.map((attrs) => {
                    return new Class(attrs);
                }),
            });
        }));
    };
}

export function classifyItem<C, I>(Class: Constructor<I, C>): TypeToClass<I, C> {
    return function(observable: Observable<I>): Observable<C> {
        return observable.pipe(map((data) => {
            return new Class(data);
        }));
    };
}

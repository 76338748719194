import { DateTime } from 'luxon';
import { DateTimeConverter } from './date-time-converter';
import { BusinessDate } from './business-date';

type HttpParamsObject = Record<string, string | number | boolean | ReadonlyArray<string | number | boolean>>;

/**
 * This functions formats the param values that do not conform to Angular's HttpParams
 */
export function formatHttpParams<T extends object>(params?: T, businessDateKeys?: (keyof T | string)[]): HttpParamsObject | undefined {
    return Object.entries(params || {}).reduce((acc, [ key, value ]) => {
        // Just skip undefined values
        if (value === undefined) {
            return acc;
        }

        // Convert null to empty string
        if (value === null) {
            return {
                ...acc,
                [key]: '',
            };
        }

        if (value instanceof BusinessDate) {
            acc[key] = DateTimeConverter.convertDateTimeToBusinessDate(value.dateTime);
            return acc;
        }

        if (value instanceof DateTime) {
            acc[key] = businessDateKeys?.includes(key as keyof T) ? DateTimeConverter.convertDateTimeToBusinessDate(value) : DateTimeConverter.convertDateTimeToUtcString(value);
            return acc;
        }

        // If it's supposed to be a key-value array
        if (key.endsWith('[]') && typeof value === 'object' && !Array.isArray(value)) {
            const arrayKey = key.slice(0, -2);
            const keyValue = Object.entries(value).reduce((carry, [ k, v ]) => {
                carry[`${arrayKey}[${k}]`] = v as HttpParamsObject[string];
                return carry;
            }, {} as HttpParamsObject);

            return {
                ...acc,
                ...keyValue,
            } as HttpParamsObject;

        }

        return {
            ...acc,
            [key]: value,
        } as HttpParamsObject;
    }, {} as HttpParamsObject);
}

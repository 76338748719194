import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileDownloadService } from '../../shared/services/file-download.service';
import { formatHttpParams } from '../../shared/utils/format-http-params';
import { map, of, switchMap } from 'rxjs';
import { NewsletterIssue, NewsletterIssueResponse } from '../models/newsletter-issue';
import { classifyArrayPaginatedResponse, classifyItem } from '../../shared/utils/rxjs/classify';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { ArrayPaginatedResponse } from '../../shared/interfaces/paginated-response';

@Injectable({
    providedIn: 'root',
})
export class NewsletterIssueService {
    private http = inject(HttpClient);
    private fileDownloadService = inject(FileDownloadService);
    private snackBarService = inject(SnackBarService);

    private issueCache: Map<string, string> = new Map();

    getAll(customerId: number, newsletterId: number) {
        return this.http.get<ArrayPaginatedResponse<NewsletterIssueResponse>>(`customers/${customerId}/newsletters/${newsletterId}/issues`).pipe(
            classifyArrayPaginatedResponse(NewsletterIssue),
        );
    }

    get(customerId: number, newsletterId: number, issueId: number) {
        return this.http.get<NewsletterIssueResponse>(`customers/${customerId}/newsletters/${newsletterId}/issues/${issueId}`).pipe(
            classifyItem(NewsletterIssue),
        );
    }

    getPreviewImage(customerId: number, newsletterId: number, issueId: number, language: string, lazy = false) {
        return this.fileDownloadService.getImageHref(`customers/${customerId}/newsletters/${newsletterId}/issues/${issueId}/download/${language}`, formatHttpParams({
            preview: true,
            lazy,
        }));
    }

    download(customerId: number, newsletterId: number, issueId: number, language: string) {
        void this.snackBarService.t('DOWNLOAD_STARTED');

        return this.get(customerId, newsletterId, issueId).pipe(
            switchMap((issue) => {
                return this.fileDownloadService.download(
                    `customers/${customerId}/newsletters/${newsletterId}/issues/${issueId}/download/${language}`,
                    issue.defaultTitle,
                );
            }),
        );
    }

    /**
     * Returns the file as an object URL so that it can be bound to an <object> or <iframe> element.
     */
    getFileObjectUrl(customerId: number, newsletterId: number, issueId: number, language: string) {
        const cacheKey = `${customerId}-${newsletterId}-${issueId}-${language}`;
        const cached = this.issueCache.get(cacheKey);
        if (cached) {
            return of(cached);
        }

        return this.http.get(`customers/${customerId}/newsletters/${newsletterId}/issues/${issueId}/download/${language}`, {
            responseType: 'blob',
        }).pipe(
            map((response) => {
                const objectUrl = URL.createObjectURL(response);
                this.issueCache.set(cacheKey, objectUrl);
                return objectUrl;
            }),
        );
    }
}

import { inject, Injectable } from '@angular/core';
import { EawUrl } from '../angularjs/modules/resource/url.service';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import type { formatHttpParams } from '../utils/format-http-params';

/**
 * A subset of the options for the third argument for {@link HttpClient.request}
 * Removed the responseType and observe properties, since they need to be set to 'blob' and 'response' respectively
 */
type DownloadOptions = {
    body?: any;
    headers?: { [header: string]: string | string[] };
    params?: { [param: string]: string | string[] };
    reportProgress?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class FileDownloadService {
    private readonly http = inject(HttpClient);
    private readonly domSanitizer = inject(DomSanitizer);

    getImageHref(url: string, params?: ReturnType<typeof formatHttpParams>) {
        return this.http.get(EawUrl.getUrl(url), {
            responseType: 'blob',
            reportProgress: true,
            params,
        })
            .pipe(
                map((blob) => {
                    return this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
                }),
            );
    }

    download(url: string, fileName?: string, options?: DownloadOptions, method: string = 'GET'): Observable<Blob> {
        return this.http.request(method, EawUrl.getUrl(url), {
            responseType: 'blob',
            reportProgress: true,
            observe: 'response',
            ...options,
        }).pipe(
            map((response): Blob => {
                const body = response.body as Blob;
                let fn: string | undefined = '';
                if (!fileName && response.headers.has('content-disposition')) {
                    const contentDisposition = response.headers.get('content-disposition') || '';
                    const match = contentDisposition.match(/filename="(.+)"/);
                    if (match) {
                        fn = match[1];
                    }
                }

                const download = URL.createObjectURL(body);
                const link = document.createElement('a');
                link.href = download;
                link.download = fileName || fn || 'no_name';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(download);

                return body;
            }),
        );
    }
}

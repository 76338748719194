var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
import { BaseLocationServices } from './baseLocationService';
/** A `LocationServices` that gets/sets the current location from an in-memory object */
var MemoryLocationService = /** @class */function (_super) {
  __extends(MemoryLocationService, _super);
  function MemoryLocationService(router) {
    return _super.call(this, router, true) || this;
  }
  MemoryLocationService.prototype._get = function () {
    return this._url;
  };
  MemoryLocationService.prototype._set = function (state, title, url, replace) {
    this._url = url;
  };
  return MemoryLocationService;
}(BaseLocationServices);
export { MemoryLocationService };

import moment from 'moment-timezone';
import { Moment } from 'moment';
import { DateTime } from 'luxon';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class DateTimeConverter {
    static convertDateTimeToBusinessDate(date: DateTime): string;
    static convertDateTimeToBusinessDate(date: unknown): string | undefined;
    static convertDateTimeToBusinessDate(date: unknown): string | undefined {
        if (date instanceof DateTime) {
            if (!date.isValid) {
                throw Error('Invalid DateTime');
            }

            return date.toFormat('yyyy-MM-dd');
        } else {
            return undefined;
        }
    }

    static convertDateTimeToUtcString(date: DateTime): string;
    static convertDateTimeToUtcString(date: unknown): string | undefined;
    static convertDateTimeToUtcString(date: unknown): string | undefined {
        if (date instanceof DateTime) {
            if (!date.isValid) {
                throw Error('Invalid DateTime');
            }

            return date.toUTC().toFormat('yyyy-MM-dd HH:mm:ss');
        } else {
            return undefined;
        }
    }

    /**
     * @deprecated
     * Converts a moment date to a string that's formatted as backend business date
     */
    static convertMomentToBusinessDate(date: unknown) {
        return moment.isMoment(date) ? date.format('YYYY-MM-DD') : undefined;
    }

    /**
     * @deprecated
     */
    static convertMomentToUtcString(date: unknown) {
        return moment.isMoment(date) ? date.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss') : undefined;
    }

    /**
     * @deprecated
     * Convert a date string to a moment object in the customer's timezone
     */
    static convertStringToMoment(date?: string | Moment, businessDate = false, format = 'YYYY-MM-DD HH:mm:ss'): Moment | undefined {
        if (moment.isMoment(date)) {
            return date;
        }
        if (typeof date !== 'string') {
            return undefined;
        }

        // If it's a business date then just take the string and set it to the customer tz immediately because then the time won't change
        // If it's not a business date set it to UTC as it's what backend gives us and then
        // update the tz to the customer tz below so that the time is changed accordingly
        const initialTz = businessDate ? moment().tz() as string : 'UTC';
        const clone = moment.tz(date, format, initialTz).clone();

        if (!businessDate) {
            clone.tz(moment().tz() as string);
        }

        return clone;
    }
}

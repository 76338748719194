import { extend, ancestors } from '../common/common';
var StateParams = /** @class */function () {
  function StateParams(params) {
    if (params === void 0) {
      params = {};
    }
    extend(this, params);
  }
  /**
   * Merges a set of parameters with all parameters inherited between the common parents of the
   * current state and a given destination state.
   *
   * @param {Object} newParams The set of parameters which will be composited with inherited params.
   * @param {Object} $current Internal definition of object representing the current state.
   * @param {Object} $to Internal definition of object representing state to transition to.
   */
  StateParams.prototype.$inherit = function (newParams, $current, $to) {
    var parents = ancestors($current, $to),
      inherited = {},
      inheritList = [];
    for (var i in parents) {
      if (!parents[i] || !parents[i].params) continue;
      var parentParams = parents[i].params;
      var parentParamsKeys = Object.keys(parentParams);
      if (!parentParamsKeys.length) continue;
      for (var j in parentParamsKeys) {
        if (parentParams[parentParamsKeys[j]].inherit == false || inheritList.indexOf(parentParamsKeys[j]) >= 0) continue;
        inheritList.push(parentParamsKeys[j]);
        inherited[parentParamsKeys[j]] = this[parentParamsKeys[j]];
      }
    }
    return extend({}, inherited, newParams);
  };
  return StateParams;
}();
export { StateParams };

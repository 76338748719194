import { ApiResponse } from '../interfaces/api-response';
import { ApiModel } from '../enums/api-model';
import { ModelCustomField, ModelCustomFieldResponse } from '../../custom-fields/models/model-custom-field';

/**
 * The purpose of this is to extend API models with simple functionality that don't interfere with the model or response in any big way.
 */
export class BaseApiModel<Response extends ApiResponse, Model> {
    readonly customFieldModel: ApiModel | undefined;
    readonly _response: Response;

    /**
     * Fields that are attached on the given model, but it's not necessarily all the available custom fields for such a model.
     */
    attachedCustomFields: ModelCustomField[] = [];

    constructor(data: Response, customFieldModel: ApiModel | undefined) {
        // Set the response as it is
        this._response = Object.freeze(data);

        // Set the model
        this.customFieldModel = customFieldModel;

        // Set the custom fields
        this.setCustomFields(data);
    }

    private setCustomFields(data: Response) {
        // Get all properties starting with "cf_" and create an instance of CustomField for each as long as the value is not nil
        this.attachedCustomFields = Object.entries(data).reduce((acc, [ key, value ]) => {
            if (!key.startsWith('cf_')) {
                return acc;
            }
            if (value == null) {
                return acc;
            }

            return [ ...acc, new ModelCustomField(value as ModelCustomFieldResponse) ];
        }, [] as ModelCustomField[]);
    }

    clone(data?: Partial<Response>): Model {
        // @ts-ignore
        return new (this.constructor)({
            ...this._response,
            ...data,
        }) as Model;
    }

    getCustomFieldValue(key: string) {
        const customField = this.attachedCustomFields?.find((x) => x.key === key);
        return !customField ? null : customField.value;
    }
}

import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, input, OnInit, signal, WritableSignal } from '@angular/core';
import { NewsletterIssueService } from '../../http/newsletter-issue.service';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'eaw-newsletter-preview',
    standalone: true,
    imports: [],
    templateUrl: './newsletter-preview.component.html',
    styleUrl: './newsletter-preview.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsletterPreviewComponent implements OnInit {
    private newsletterIssueService = inject(NewsletterIssueService);
    private elementRef = inject(ElementRef);

    customerId = input.required<number>();
    newsletterId = input.required<number>();
    issueId = input.required<number>();
    language = input.required<string>();
    lazy = input<boolean>(false);

    previewSrc: WritableSignal<SafeResourceUrl | undefined> = signal(undefined);

    constructor() {
        effect(() => {
            if (this.previewSrc()) {
                (this.elementRef.nativeElement as HTMLElement).classList.remove('skeleton');
            } else {
                (this.elementRef.nativeElement as HTMLElement).classList.add('skeleton');
            }
        });
    }

    ngOnInit() {
        this.getIssuePreview();
    }

    getIssuePreview() {
        this.newsletterIssueService.getPreviewImage(
            this.customerId(),
            this.newsletterId(),
            this.issueId(),
            this.language(),
            this.lazy(),
        ).subscribe((response) => {
            this.previewSrc.set(response);
        });
    }
}

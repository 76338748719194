import { PropertyValue } from '../models/property';
import { SettingDataType } from '../models/setting';
import { DateTime } from 'luxon';

export class PropertyValueDecoder {
    readonly value: PropertyValue;
    readonly dataType?: SettingDataType;

    /**
     * Creates an instance of PropertyValueDecoder.
     * @param value
     * @param dataType - Not optional because we don't want to accidentally use the wrong input type or forget to pass it.
     */
    constructor(value: unknown, dataType: SettingDataType | undefined) {
        if (value == null) {
            this.value = '';
        } else if (typeof value === 'string') {
            this.value = value;
        } else if (typeof value === 'object') {
            this.value = JSON.stringify(value);
        } else {
            this.value = String(value);
        }
        this.dataType = dataType;
    }

    toString() {
        return this.asString();
    }

    asKeyValue() {
        let result: Record<string, any> | null = null;

        try {
            result = JSON.parse(this.value);
        } catch (_) {
            // Do nothing
        }

        return result;
    }

    asString<T = string>() {
        return String(this.value) as T;
    }

    asInteger() {
        const result = parseInt(this.value);
        return isNaN(result) ? null : result;
    }

    asFloat() {
        const result = parseFloat(this.value);
        return isNaN(result) ? null : result;
    }

    asBoolean() {
        if (this.value === '1' || this.value === 'true') {
            return true;
        }

        if (this.value === '0' || this.value === 'false') {
            return false;
        }

        return null;
    }

    asTime() {
        const [ hours, minutes ] = this.value.split(':');
        if (hours == null || minutes == null) {
            return null;
        }

        const hoursInt = parseInt(hours);
        const minutesInt = parseInt(minutes);
        if (isNaN(hoursInt) || isNaN(minutesInt)) {
            return null;
        }

        const result = DateTime.now().startOf('day').set({ hour: hoursInt, minute: minutesInt });
        return result.isValid ? result : null;
    }

    asArray<T extends string>(type: 'json-formatted' | 'comma-separated-string' = 'json-formatted') {
        let result: T[] | null = null;

        if (type === 'json-formatted') {
            try {
                result = JSON.parse(this.value);
            } catch (_) {
                // Do nothing
            }
        }

        if (type === 'comma-separated-string') {
            result = this.value.split(',') as T[];
        }

        return result;
    }
}

import { ApiResponse } from '../../shared/interfaces/api-response';
import { NewsletterLanguages } from './newsletter';
import { Property, PropertyResponse } from '../../shared/models/property';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';
import { BaseApiModel } from '../../shared/models/base-api-model';

export interface NewsletterIssueResponse extends ApiResponse {
    default_description: string | null;
    default_title: string;
    id: number;
    is_read: boolean;
    language_code: string;
    languages: NewsletterLanguages;
    newsletter_id: number;
    properties?: PropertyResponse[];
    publish_date: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export class NewsletterIssue extends BaseApiModel<NewsletterIssueResponse, NewsletterIssue> {
    defaultDescription: string | null;
    defaultTitle: string;
    id: number;
    isRead: boolean;
    languageCode: string;
    languages: NewsletterLanguages;
    newsletterId: number;
    properties?: Property[];
    publishDate: DateTime | null;
    createdAt: DateTime;
    updatedAt: DateTime;
    deletedAt: DateTime | null;

    constructor(data: NewsletterIssueResponse) {
        super(data, undefined);

        this.defaultDescription = data.default_description;
        this.defaultTitle = data.default_title;
        this.id = data.id;
        this.isRead = data.is_read;
        this.languageCode = data.language_code;
        this.languages = data.languages;
        this.newsletterId = data.newsletter_id;
        this.properties = data.properties?.map((property) => new Property(property));
        this.publishDate = data.publish_date ? stringToDateTime(data.publish_date) : null;
        this.createdAt = stringToDateTime(data.created_at);
        this.updatedAt = stringToDateTime(data.updated_at);
        this.deletedAt = data.deleted_at ? stringToDateTime(data.deleted_at) : null;
    }

    getTitle(languageCode: string) {
        return this.languages[languageCode]?.title || this.defaultTitle;
    }

    getDescription(languageCode: string) {
        return this.languages[languageCode]?.description || this.defaultDescription;
    }

    getLanguageCode(languageCode?: string) {
        if (languageCode && languageCode in this.languages) {
            return languageCode;
        }

        return this.languageCode;
    }
}
